
import { RotatingLines } from 'react-loader-spinner'
import { motion } from "framer-motion";

const GalleryLoader = ({ color }) => {
    return (
        <>
            <motion.div className="loaderScreen" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                <RotatingLines
                    strokeColor={color}
                    strokeWidth="5"
                    animationDuration="1"
                    width="50"
                    visible={true}
                />
            </motion.div>
        </>
    )
}

export default GalleryLoader