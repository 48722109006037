
import React, { useEffect, useState } from 'react'
import { useLocation } from "react-router-dom";
import { ReactSession } from 'react-client-session';
import { HelmetProvider, Helmet } from "react-helmet-async"
import ScrollToTop from "./components/ScrollToTop";
import { AnimatePresence } from "framer-motion"
import { useIntro } from './context/introContext'
import { SearchProvider } from './context/searchContext'
import { useLanguage } from './context/languageContext'
import NavbarX from './components/Navbar'
import { GetRoutes } from './components/Routes'
import Chatbase from './components/Chatbase'

function App() {
  const { skipIntro, setSkip } = useIntro();
  const location = useLocation()
  const { language, setLanguage } = useLanguage();
  ReactSession.setStoreType("localStorage");

  useEffect(() => {
    let newLanguage = ReactSession.get('flex-language')
    if (language !== newLanguage) {
      setLanguage(language)
    }
    if (location.pathname !== '/' && !skipIntro) {
      setSkip()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  return (
    <HelmetProvider>
      <Helmet>
        <script async src={`https://www.googletagmanager.com/gtag/js?id=AW-16561319806`}></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'AW-16561319806');
          `}
        </script>
      </Helmet>
      <div className="scrollbar-container">
        <SearchProvider>
          <ScrollToTop />
          {location.pathname !== '/' &&
            <>
              <NavbarX></NavbarX>
            </>}
          <Chatbase></Chatbase>
          <AnimatePresence mode="wait" initial={false}>
            {React.cloneElement(GetRoutes(), { key: location.pathname })}
          </AnimatePresence>
        </SearchProvider>
      </div>
    </HelmetProvider>
  );
}

export default App;
