
import React from 'react'
import { motion, useIsPresent } from "framer-motion"
import { useNavigate } from "react-router-dom";
import { FooterFixed } from '../components/Footer'
import video from '../assets/videos/66a1058e-b0c2-473a-b61e-13feb0a19840.mp4'

const Landing = () => {
    const navigate = useNavigate();
    const isPresent = useIsPresent();

    return (
        <>
            <div className="video-container" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                <video className='black-and-white-filter' autoPlay loop muted playsInline>
                    <source src={video} type="video/mp4" />
                </video>
                <div className="logoContent" onClick={() => navigate('/projetos', {
                    state: {
                        origin: 'landing',
                    }
                })}>
                    <h6>Entrar</h6>
                </div>
                <FooterFixed></FooterFixed>
            </div>
            <motion.div
                initial={{ scaleX: 1 }}
                animate={{ scaleX: 0, transition: { duration: 1, ease: "circOut" } }}
                exit={{ scaleX: 1, transition: { duration: 1, ease: "circIn" } }}
                style={{ originX: isPresent ? 0 : 1 }}
                className="privacy-screen"
            />
        </>
    )
}

export default Landing