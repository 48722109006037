
import React from 'react'
import { Container } from 'react-bootstrap'
import { motion } from "framer-motion";
import { FooterFixed } from '../components/Footer'

const PrivacyPolitics = () => {

    return (
        <motion.div className="about" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <div className="App">
                <Container fluid className="position-relative overflow-hidden">
                    <div className="divider gradient mt-5"></div>
                    <Container fluid="sm" className="mb-4">
                        <h1 className="text-main-color text-center mt-4 text-uppercase">
                            POLÍTICA DE PRIVACIDADE DA FLEX INTERATIVA
                        </h1>
                        <h6 className='mb-5 text-center'>(Última atualização em 11 de outubro de 2021)</h6>
                        <div className="mt-4 fs-14">
                            A <b>FLEX</b> se preocupa com a privacidade e a proteção de dados pessoais de seus clientes, parceiros, colaboradores, fornecedores e terceiros relacionados
                            direta ou indiretamente às suas atividades, por isso segue as diretrizes da Lei Geral de Proteção de Dados Pessoais (“LGPD”),
                            do Regulamento Geral sobre a Proteção de Dados (“RGPD” ou “GDPR”), e outras normas que constituem o arcabouço legal da
                            proteção de dados pessoais no mundo, e visam resguardar os direitos e garantias fundamentais dos titulares de dados pessoais.
                            <br></br><br></br>
                            Para os fins desta política, são consideradas as seguintes definições:
                            <br></br><br></br>
                            <b>Agente de Tratamento:</b> O controlador e o operado.
                            <br></br>
                            <b>ANPD ou Autoridade Nacional de Proteção de Dados:</b> Órgão da administração pública indireta responsável por zelar, implementar e fiscalizar o cumprimento da Lei de Proteção de Dados Pessoais aplicável.
                            <br></br>
                            <b>Compartilhamento de dados pessoais:</b> Comunicação, difusão, transferência nacional ou internacional, interconexão de dados pessoais ou tratamento compartilhado de bancos de dados pessoais por órgãos, entidades ou pessoais, e para uma ou mais modalidades de tratamento.
                            <br></br>
                            <b>Consentimento:</b> Manifestação livre, informada e inequívoca pela qual o Titular dos dados pessoais concorda com o tratamento de seus dados pessoais para uma finalidade determinada.
                            <br></br>
                            <b>Controlador:</b> Pessoa física ou jurídica, de direito público ou privado, a quem competem as decisões referentes ao tratamento de dados pessoais.
                            <br></br>
                            <b>Dado anonimizado:</b> Dado que não identifica de forma direta ou indireta um titular dos dados pessoais, considerando a utilização de meios técnicos razoáveis e disponíveis na ocasião de seu tratamento.
                            <br></br>
                            <b>Dado pessoal sensível:</b> Dado pessoal sobre origem racial ou étnica, convicção religiosa, opinião política, filiação a sindicato ou a organização de caráter religioso, filosófico ou político, dado referente à saúde ou à vida sexual, dado genético ou biométrico, quando vinculado a uma pessoa física.
                            <br></br>
                            <b>Dado pessoal:</b> Informação relacionada à pessoa física identificada ou identificável.
                            <br></br>
                            <b>Encarregado pelo tratamento de dados pessoais:</b> Pessoa física ou jurídica indicada pela <b>FLEX</b> e que atua como canal de comunicação entre esta, os Titulares dos dados pessoais e a Autoridade de Proteção de Dados.
                            <br></br>
                            <b>Operador:</b> Pessoa física ou jurídica, de direito público ou privado, que realiza o tratamento de dados pessoais em nome do Controlador.
                            <br></br>
                            <b>Tratamento de Dados Pessoais:</b> Toda operação realizada com dados pessoais, como as que se referem a coleta, produção, recepção, classificação, utilização, acesso, reprodução, transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação, avaliação ou controle da informação, modificação, comunicação, transferência, difusão ou extração.
                            <br></br><br></br>
                            <i>A presente política se aplica a todos aqueles que acessarem nosso site e/ou se utilizarem, de alguma forma, dos nossos serviços (aplicativos, softwares, plataformas digitais etc. – coletiva e indistintamente denominados “Serviços”).</i>
                            <br></br><br></br>
                            <b>Vigência</b>
                            <br></br>
                            <br></br>
                            A presente política passa a viger a partir da sua publicação, sendo aplicável ao usuário a partir do exato momento em que este acessar os nossos canais, produtos e/ou serviços consentindo com o presente conteúdo.
                            <br></br>
                            Reservamo-nos o direito de modificar a presente Política de Privacidade a qualquer tempo diante de eventuais alterações legislativas ou jurisprudenciais, ou qualquer as alterações forem necessárias para aprimorar e/ou ajustar as nossas atividades. Assim, sugerimos a consulta periódica desta política, como forma de manter-se atualizado quanto ao seu conteúdo.
                            <br></br><br></br>
                            <b>Não Concordância</b>
                            <br></br>
                            <br></br>
                            EM CASO DE NÃO CONCORDÂNCIA COM QUALQUER DISPOSIÇÃO OU MODIFICAÇÃO DESTA POLÍTICA, POR FAVOR, CESSE IMEDIATAMENTE O USO DE NOSSOS SERVIÇOS, SISTEMAS E PLATAFORMAS.
                            <br></br>
                            <br></br>
                            <b>Dúvidas & Sugestões</b>
                            <br></br>
                            <br></br>
                            Para esclarecimento de quaisquer dúvidas, bem como envio de sugestões  relacionadas à esta Política, sinta-se à vontade para contatar-nos pelo e-mail: dpo@flexinterativa.com.br
                            <br></br><br></br>
                            <i>PARA A SUA SEGURANÇA E DA FLEX, EM CASO DE SOLICITAÇÃO DE ASSISTÊNCIA OU CONTATO, PODERÁ SER NECESSÁRIA A AUTENTICAÇÃO DE SUA IDENTIDADE ANTES DE ATENDERMOS À SUA SOLICITAÇÃO.</i>
                            <br></br>
                            <br></br>
                            <b>Declaração</b>
                            <br></br>
                            Em suas operações a <b>FLEX</b> pode realizar o tratamento de dados pessoais, bem como de outras informações necessárias às suas atividades. A seguir, conheça um pouco mais sobre nossas práticas para garantir o devido cumprimento da nossa Política de Privacidade:
                            <br></br><br></br>
                            <b>(a)</b> nossos Serviços são desenvolvidos para minimizar o uso de dados pessoais. Isso significa que nós apenas tratamos os dados necessários para atingir as finalidades dispostas nesta Política;
                            <br></br>
                            <b>(b)</b> a <b>FLEX</b> adota todas as medidas de segurança da informação, tecnológica e legalmente disponíveis, para proteger os seus dados contra incidentes de segurança da informação e de dados pessoais, tais como acessos não autorizados, vírus, vazamento de informações etc.;
                            <br></br>
                            <b>(c)</b> a <b>FLEX</b> armazena dados pessoais somente durante o período legal, contratual ou minimamente necessário para alcançar a sua finalidade;
                            <br></br>
                            <b>(d)</b> nós não realizamos atividades de tomada de decisão automatizada ou de criação de perfis, a exemplo do profiling;
                            <br></br>
                            <b>(e)</b> nós não compartilhamos dados pessoais com empregadores, seguradoras ou parentes e amigos. Nenhum deles terá acesso aos dados, exceto se o usuário cadastrado expressamente autorizar ou, ainda, se forem compartilhados por ele próprio de maneira pública ou com terceiros.
                            <br></br><br></br>
                            <b>AO COMPARTILHAR COM TERCEIROS E/OU PUBLICAMENTE OS DADOS PESSOAIS, A <b>FLEX</b> NÃO PODERÁ EXCLUÍ-LOS DOS MECANISMOS DE BUSCA E/OU DOS DEMAIS APLICATIVOS, PLATAFORMAS OU BANCOS DE DADOS, DE PROPRIEDADE DE TERCEIROS.</b>
                            <br></br><br></br>
                            Além das nossas práticas acima relacionadas, a <b>FLEX</b> também garante aos seus usuários:
                            <br></br><br></br>
                            <b>(a)</b> a solicitação de esclarecimentos e informações a respeito do tratamento de seus dados pessoais pela <b>FLEX</b> por meio do e-mail dpo@flexinterativa.com.br.
                            <br></br>
                            <b>(b)</b> o acesso às informações por meio da solicitação de backup de dados – portabilidade de dados;
                            <br></br>
                            <b>(c)</b> a revogação do consentimento para o tratamento de dados a qualquer momento, bastando para tanto: (i) a exclusão da respectiva conta FLEX; e/ou (ii) excluir nossos Serviços dos dispositivos; e/ou, ainda, (iii) cancelar a assinatura de nossa newsletter, clicando no link disponibilizado no rodapé dos e-mails por nós enviados ou entrando em contato conosco por meio do e-mail dpo@flexinterativa.com.br.
                            <br></br>
                            <b>(d)</b> a retificação das informações armazenadas nas configurações dos Serviços;
                            <br></br>
                            <b>(e)</b> a solicitação da exclusão absoluta de dados por meio do e-mail dpo@flexinterativa.com.br Uma vez deferida a exclusão, os dados serão excluídos permanentemente em até 60 (sessenta) dias contados da data do deferimento;
                            <br></br>
                            <b>(f)</b> a apresentação de reclamação perante a autoridade competente nos casos em que o usuário acreditar que estamos tratando seus dados em desconformidade com a legislação aplicável. (Para saber quem é a autoridade competente, consulte a legislação pertinente – LGPD e seus regulamentos.)
                            <br></br><br></br>
                            <b>Dados de Crianças & Adolescentes</b>
                            <br></br><br></br>
                            Os Serviços da <b>FLEX</b> destinam-se a pessoas maiores de 18 (dezoito) anos, deste modo nós não tratamos dados pessoais de crianças e adolescentes. Caso sejam localizados dados de pessoas menores de 18 (dezoito) anos em nossas bases de dados, esses serão imediatamente eliminados, utilizando-se padrões elevados de segurança que garantam a total exclusão.
                            <br></br><br></br>
                            <b>Dados tratados pela FLEX</b>
                            <br></br><br></br>
                            O tratamento de dados está relacionado a todos os Serviços prestados pela <b>FLEX</b>.
                            <br></br><br></br>
                            Por isso, para que possamos sempre aprimorar nossos Serviços, bem como nossos serviços comerciais e de suporte ao cliente, nós coletamos, recebemos, armazenamos algumas informações sobre nossos usuários, como por exemplo:
                            <br></br><br></br>
                            <b>(a) Dados de Contato:</b>  são os dados que os usuários voluntariamente nos fornecem, que incluem: nome, endereço de e-mail, código postal, número de telefone;
                            <br></br>
                            <b>(b) Dados de Uso:</b>  são os dados relativos ao uso dos serviços, tais como: idioma de preferência, localização, tipo de dispositivo, tipo de navegador, endereço IP, dados de desempenho;
                            <br></br>
                            <b>(d) Dados de Terceiros:</b>  são os dados, públicos ou não, que o usuário fornece, garantindo poder fazê-lo e responsabilizando-se pelo compartilhamento. Podem ser de natureza de contato, de uso etc.;
                            <br></br>
                            <b>(e) Cookies:</b>  utilizamos cookies próprios e de terceiros para coletar dados sobre os visitantes de nosso site https://flexinterativa.com.br. Os Cookies podem incluir: Dados de Contato, Dados de Uso. Mais informações sobre Cookies abaixo.
                            <br></br><br></br>
                            <b>Cookies</b>
                            <br></br><br></br>
                            A transparência e confiança são elementos fundamentais para o nosso relacionamento. E é por essa razão, que a seguir explicaremos como utilizamos a tecnologia dos cookies em nosso site e Serviços.
                            <br></br><br></br>
                            As disposições sobre cookies abaixo aplicam se a todo e qualquer Serviço da <b>FLEX:</b>
                            <br></br> <br></br>
                            <i>Antes de tudo, o que são cookies?</i>
                            <br></br>
                            Cookies são pequenos arquivos em formato de texto, armazenados no navegador que os usuários utilizam para acessar nosso site e Serviços, que permite à <b>FLEX</b> ou terceiros reconhecer quem eles são.
                            <br></br><br></br>
                            <i>Qual a utilidade dos cookies?</i>
                            <br></br>
                            Geralmente, os cookies são utilizados para coletar, armazenar e compartilhar informações sobre suas atividades em sites, tais como o nosso. Durante um certo período, para que os usuários não tenham que inserir novamente suas preferências e ações, os cookies permitem que os sites lembrem de suas preferências e atividades, como por exemplo, seu login, idioma de preferência, localização etc.
                            <br></br><br></br>
                            <i>Quais os fundamentos para o uso de cookies?</i>
                            <br></br><br></br>
                            Os cookies podem ser utilizados para:
                            <br></br>
                            <b>(a)</b> permitir certa funcionalidade do site ou Serviço;
                            <br></br>
                            <b>(b)</b> análises e estatísticas de uso do site ou Serviço;
                            <br></br>
                            <b>(c)</b> armazenamento de atividades e preferências;
                            <br></br>
                            <b>(d)</b> permitir a publicidade e marketing personalizados.
                            <br></br><br></br>
                            <i>Quais os cookies que a <b>FLEX</b> utiliza?</i>
                            <br></br><br></br>
                            Utilizamos os seguintes tipos de cookies:
                            <br></br>  <br></br>
                            <b>(a) Cookies de Sessão.</b> São aqueles utilizados para reconhecer um visitante em específico no nosso site e Serviços. Nós os usamos para identificar o visitante durante uma única sessão de navegação e quando o visitante efetua o login em nosso site ou Serviço. Os Cookies de Sessão têm um período de vida curto e determinado, e também expiram quando o visitante encerra sua sessão em seu navegador da web (Internet Explorer, Mozila Firefox, Google Chrome etc.;
                            <b>(b) Cookies Permanentes.</b> Estes cookies são aqueles que têm um período de vida um pouco maior do que os Cookies de Sessão, pois remanescem nos dispositivos por um pequeno período especificado no próprio Cookie Permanente. A <b>FLEX</b> utiliza Cookies Permanentes quando necessários para identificar visitantes por um período maior do que o permitido por um Cookie de Sessão, como nas hipóteses em que o visitante solicita para permanecer logado em nosso site.
                            <br></br> <br></br>
                            Em algumas situações específicas podemos também utilizar os seguintes cookies:
                            <br></br><br></br>
                            <b>(a) Cookies de Autenticação.</b> Quando o visitante efetua login em nosso site e Serviços, os Cookies de Autenticação nos ajudam a personalizar sua experiência, de acordo com as suas ações e preferências;
                            <br></br>
                            <b>(b) Cookies de Segurança.</b> São os cookies que utilizamos para habilitar e suportar nossos recursos de segurança, ajudando ainda na detecção de atividades maliciosas em nosso site e Serviços;
                            <br></br>
                            <b>(c) Cookies de Desempenho e Análise.</b> São aqueles que nos ajudam a compreendem o desempenho de nosso site e Serviços em localizações distintas, bem como possibilitam o entendimento, aprimoramento e análise de nossos Serviços e recursos.
                            <br></br><br></br>
                            <b>Fundamentos do Tratamento de Dados pela FLEX</b>
                            <br></br><br></br>
                            De acordo com as preferências dos usuários e com o Serviço que este utiliza, a <b>FLEX</b> basicamente trata seus dados para  oferecer, analisar, aprimorar e personalizar os Serviços e esforços de marketing direcionados a clientes, além de processar seu cadastro, solicitações e eventuais pagamentos, bem como realizar interação com seus clientes, parceiros e fornecedores.
                            <br></br><br></br>
                            Compartilhamento de Dados pela FLEX
                            <br></br><br></br>
                            Nós não compartilhamos os dados de usuários com terceiros, exceto quando estritamente necessário:
                            <br></br><br></br>
                            <b>(a)</b> para facilitar o envio de notificações e e-mails;
                            <b>(b)</b> para fornecer conteúdos de marketing e de publicidade quando previamente autorizado ou amparado por uma base legal;
                            <b>(c)</b> para fins de armazenamento ou outra operação com o terceiro;
                            <b>(d)</b> nos ajudar a acompanhar as métricas de sucesso de conversão do site;
                            <b>(e)</b> gerenciar nossas vendas e nosso serviço de atendimento ao cliente.
                            <br></br><br></br>
                            A fim de garantir que os terceiros com quem compartilhamos eventualmente os dados estejam em conformidade com esta Política, bem como os altos níveis de confidencialidade e boas práticas de privacidade, proteção de dados e segurança da informação, celebramos junto a eles acordos de confidencialidade e outros documentos de aderência.
                            <b></b>   <b></b>
                            Outrossim, estamos sempre revisando nossos padrões de privacidade, proteção de dados e de segurança da informação por meio das atualizações de nossas políticas, testes de vulnerabilidade, implementação de ferramentas e soluções contra vírus e malware etc.
                            <b></b><br></br>
                            <i>Finalidades para o Tratamento de Dados pela FLEX:</i>
                            <b></b><br></br>
                            Os dados pessoais acima mencionados, são utilizados para personalizar o atendimento do usuário, inserir suas preferências relacionadas a disponibilidade e apontar os profissionais disponíveis mais indicados para a prestação de serviços, podendo, ainda, ser utilizados para publicidade e marketing direcionado sempre que permitido previamente pelo usuário ou amparado por alguma outra hipótese legal.
                            <b></b><br></br>
                            <i>Declaração e assinatura:</i>
                            <br></br><br></br>
                            Ao assinar este termo, você informa que compreendeu como a Política de Privacidade geral da <b>FLEX</b> funciona, como ela armazena os dados e quais as finalidades.
                            <br></br><br></br>
                            Em acordo com a Lei Geral de Proteção de Dados Lei nº 13.709/2018, o funcionário, agente, terceirizado, prestador de serviços ou parceiro da <b>FLEX</b> é vedado de praticar os seguintes atos com os dados captados e armazenados pela FLEX:
                            <br></br><br></br>
                            <b>(a)</b> utilizar os dados para função divergente da captação;
                            <br></br>
                            <b>(b)</b> compartilhar os dados fora dos sistemas da FLEX;
                            <br></br>
                            <b>(c)</b> armazenar pessoalmente, seja em computador próprio, e-mail pessoal, pen drive, HD externo, site, drive, link ou qualquer outra forma de armazenamento de dados que não seja o próprio sistema da FLEX;
                            <br></br><br></br><br></br>

                            <p className="float-end">São Paulo, 11 de outubro de 2021.</p>


                        </div>
                    </Container>
                    <FooterFixed></FooterFixed>
                </Container>
            </div>
        </motion.div>
    )
}

export default PrivacyPolitics