import React, { useMemo, useState, useEffect } from 'react'

export const TextWithLineBreaks = ({ className, text }) => {
    if (!text) {
        return
    }
    const formattedText = text.split("\n").map((line, index) => (
        <React.Fragment key={index}>
            {line}
            {index !== text.length - 1 && <br />}
        </React.Fragment>
    ));
    return <span>{formattedText}</span>;
}

export const shuffleArray = (array) => {
    return array.sort(() => Math.random() - 0.5)
}

export function redirectURL(open, url) {
    if (open === true) {
        window.open(url, '_blank')
    } else {
        window.location.replace(url)
    }
}

export function useIsInViewport(ref) {
    const [isIntersecting, setIsIntersecting] = useState(false);

    const observer = useMemo(
        () =>
            new IntersectionObserver(
                ([entry]) => {
                    if (!isIntersecting) {
                        setIsIntersecting(entry.isIntersecting);
                    }
                },
                { threshold: 1 } // Change the threshold value as needed (0.5 for 50% visibility)
            ),
        []
    );

    useEffect(() => {
        observer.observe(ref.current);

        return () => {
            observer.disconnect();
        };
    }, [ref, observer]);

    return isIntersecting;
}