
import React, { useState, useRef } from 'react'
import { Container } from 'react-bootstrap'
import { motion } from "framer-motion"
import { clientsLogo } from '../components/Json'
import { FooterFixed } from '../components/Footer'
import { useIsInViewport } from '../components/functions'
import CountUp from 'react-countup';
import { Helmet } from 'react-helmet';

const About = () => {
    const [scrollPosition, setScrollPosition] = useState(0);
    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);

    const isInViewport1 = useIsInViewport(ref1);
    console.log('isInViewport1: ', isInViewport1);

    const isInViewport2 = useIsInViewport(ref2);
    console.log('isInViewport2: ', isInViewport2);

    const isInViewport3 = useIsInViewport(ref3);
    console.log('isInViewport3: ', isInViewport3);

    return (
        <>
            <Helmet>
                <title>Flex Interativa - SOBRE</title>
                <meta name="description" content="Há mais de 8 anos na liderança das tecnologias que revolucionam a forma como nos conectamos com o mundo, nossa competência e experiência com realidade Aumentada e Tecnologias Imersivas nos permite suprir as mais diversas demandas de projetos e eventos. Mantemos um compromisso sólido em permanecer à frente dessas inovações, visando oferecer resultados excepcionais aos nossos clientes" />
                <meta name="robots" content="index, follow" />
            </Helmet>
            <motion.div className="about" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                <div className="App">
                    <Container fluid className="position-relative overflow-hidden">
                        <div className="divider gradient mt-5"></div>
                        <Container fluid="sm" className="mb-4">
                            <h1 className="text-main-color text-center mt-4 text-uppercase">
                                A EMPRESA
                            </h1>
                            <h5 className='mt-5'>Missão</h5>
                            <p>
                                Na Flex Interativa, nos dedicamos a fornecer soluções pioneiras que unem criatividade e inovação no campo das tecnologias interativas e imersivas. Estamos comprometidos em impulsionar o sucesso dos nossos clientes, guiando-os através da jornada da transformação digital.
                            </p>
                            <h5 className='mt-5'>Pioneirismo em Realidade Aumentada e Tecnologias Imersivas</h5>
                            <p>
                                Há mais de 8 anos na liderança das tecnologias que revolucionam a forma como nos conectamos com o mundo, nossa competência e experiência com realidade Aumentada e Tecnologias Imersivas nos permite suprir as mais diversas demandas de projetos e eventos. Mantemos um compromisso sólido em permanecer à frente dessas inovações, visando oferecer resultados excepcionais aos nossos clientes.
                            </p>
                            <h5 className='mt-5'>Excelência Reconhecida</h5>
                            <p>
                                Nosso compromisso com a excelência é reconhecido em todo o Brasil. Com orgulho, destacamos nossa participação no Shark Tank, um testemunho do nosso compromisso com a inovação e a qualidade. Recebemos prêmios e reconhecimentos que atestam nosso comprometimento com a entrega de soluções excepcionais.
                            </p>
                            <div className="aboutMiddleCard px-0 mx-0 row">
                                <div className="col-md-4 d-flex" ref={ref1}>
                                    <h4>
                                        {!isInViewport1 ?
                                            (
                                                <>
                                                    <span className="h1 text-main-color">
                                                        +<span>0</span>
                                                    </span>
                                                    <br></br>
                                                    anos DE EXPERTISE EM TECNOLOGIAS IMERSIVAS
                                                </>
                                            ) :
                                            (
                                                <>
                                                    <CountUp start={0} end={8} delay={0} duration={5}>
                                                        {({ countUpRef }) => (
                                                            <>
                                                                <span className="h1 text-main-color">
                                                                    +<span ref={countUpRef}>0</span>
                                                                </span>
                                                                <br></br>
                                                                anos DE EXPERTISE EM TECNOLOGIAS IMERSIVAS
                                                            </>
                                                        )}
                                                    </CountUp>
                                                </>
                                            )}
                                    </h4>
                                </div>
                                <div className="col-md-4 d-flex" ref={ref2}>
                                    <h4>
                                        {!isInViewport1 ?
                                            (
                                                <>
                                                    <span className="h1 text-main-color">
                                                        +<span>0</span>
                                                    </span>
                                                    <br></br>
                                                    EVENTOS<br></br>ATENDIDOS
                                                </>
                                            ) :
                                            (
                                                <>
                                                    <CountUp start={0} end={1000} delay={0} duration={5}>
                                                        {({ countUpRef }) => (
                                                            <>
                                                                <span className="h1 text-main-color">
                                                                    +<span ref={countUpRef}>0</span>
                                                                </span>
                                                                <br></br>
                                                                EVENTOS<br></br>ATENDIDOS
                                                            </>
                                                        )}
                                                    </CountUp>
                                                </>
                                            )}
                                    </h4>
                                </div>
                                <div className="col-md-4 d-flex" ref={ref3}>
                                    <h4>
                                        {!isInViewport1 ?
                                            (
                                                <>
                                                    <span className="h1 text-main-color">
                                                        +<span>0</span>
                                                    </span>
                                                    <br></br>
                                                    PROJETOS<br></br>REALIZADOS
                                                </>
                                            ) :
                                            (
                                                <>
                                                    <CountUp start={0} end={1000} delay={0} duration={5}>
                                                        {({ countUpRef }) => (
                                                            <>
                                                                <span className="h1 text-main-color">
                                                                    +<span ref={countUpRef}>0</span>
                                                                </span>
                                                                <br></br>
                                                                PROJETOS<br></br>REALIZADOS
                                                            </>
                                                        )}
                                                    </CountUp>
                                                </>
                                            )}
                                    </h4>
                                </div>
                            </div>
                            <h5 className='mt-3'>42 Labs: Uma Fusão Transformadora</h5>
                            <p>
                                Em 2022, consolidamos nossa posição como líderes ao unir forças com a 42 Labs. A Flex Interativa agora conta com especialistas na aplicação de soluções de tecnologia na realização de eventos presenciais, online e híbridos.<br></br><br></br>A fusão das empresas torna o objetivo principal unir expertises e portfólios de diversos produtos e serviços, aumentando a capacidade de entregar ainda mais qualidade e eficiência no uso da realidade aumentada e do metaverso para todo tipo de evento corporativo.
                            </p>
                            <h1 className="text-main-color text-center mt-5 pt-5 text-uppercase">
                                CLIENTES
                            </h1>
                            <div className="clients row horizontally-centered pt-3">
                                {clientsLogo.map((option, i) => {
                                    return (
                                        <div className="col my-3 mx-2" key={i}><img src={option} alt={`logo${i}`} style={{ maxWidth: '100px' }} /></div>
                                    )
                                })}
                                <h6 className="text-center mt-4 mb-0">Entre outros...</h6>
                            </div>
                        </Container>
                        <FooterFixed></FooterFixed>
                    </Container>
                </div>
            </motion.div >
        </>
    )
}

export default About