import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from "react-router-dom";
// Create a new context
const SearchContext = createContext();

// Create a provider component
export const SearchProvider = ({ children }) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredResults, setFilteredResults] = useState([]);

    const contextValue = {
        searchQuery,
        setSearchQuery,
        filteredResults,
        setFilteredResults,
    };

    useEffect(() => {
        if (pathname !== '/projetos' && pathname !== '/' && searchQuery !== '') {
            navigate("/projetos")
        }
    }, [searchQuery]);

    return (
        <SearchContext.Provider value={contextValue}>
            {children}
        </SearchContext.Provider>
    );
};

// Create a custom hook to use the context
export const useSearchContext = () => {
    return useContext(SearchContext);
};