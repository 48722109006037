
import React, { useState, useMemo } from 'react'
import { Container, Collapse } from 'react-bootstrap'
import { useNavigate } from "react-router-dom";
import {
    faArrowAltCircleDown,
    faArrowAltCircleUp
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import { FooterFixed } from '../components/Footer'
import { category } from '../components/Json'
import { useIsInViewport } from '../components/functions'
import { Helmet } from 'react-helmet';

const About = () => {

    return (
        <>
            <Helmet>
                <title>Flex Interativa - SOLUÇÕES TECNOLÓGICAS</title>
                <meta name="description" content="No mundo atual, o avanço tecnológico está se movendo em um ritmo vertiginoso, e as empresas estão constantemente buscando maneiras inovadoras de aprimorar suas estratégias de marketing e proporcionar experiências memoráveis aos consumidores e nesse cenário, diversas tecnologias têm se destacado, revolucionando a forma como as empresas interagem com seus clientes e promovem seus produtos e serviços. Explore algumas dessas tecnologias e entenda o potencial de impacto no mercado que a Flex Interativa pode trazer para sua empresa" />
                <meta name="robots" content="index, follow" />
            </Helmet>
            <div className="App">
                <Container fluid className="position-relative overflow-hidden">
                    <div className="divider gradient mt-5"></div>
                    <Container fluid="sm">
                        <h1 className="text-main-color text-center mt-4 text-uppercase">
                            SOLUÇÕES TECNOLÓGICAS
                        </h1>
                        <p className="mt-5">
                            No mundo atual, o avanço tecnológico está se movendo em um ritmo vertiginoso, e as empresas estão constantemente buscando maneiras inovadoras de aprimorar suas estratégias de marketing e proporcionar experiências memoráveis aos consumidores e nesse cenário, diversas tecnologias têm se destacado, revolucionando a forma como as empresas interagem com seus clientes e promovem seus produtos e serviços.<br></br><br></br>Explore algumas dessas tecnologias e entenda o potencial de impacto no mercado que a Flex Interativa pode trazer para sua empresa.
                        </p>
                        <div className="section_our_solution mb-4">
                            <div className="row mx-0">
                                {category.map((item, index) => (
                                    <CategoryCard item={item} key={index} index={index}></CategoryCard>
                                ))}
                            </div>
                        </div>
                    </Container>
                    <FooterFixed></FooterFixed>
                </Container>
            </div>
        </>
    )
}

export const CategoryCard = ({ item, index }) => {
    const ref = useMemo(() => React.createRef(), [index]); // Memoize the ref
    const isInViewport = useIsInViewport(ref);
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    return (
        <motion.div className={`col-md-6 col-sm-12 py-3 ps-0 pe-4`} initial={{ x: 0 }} animate={{ x: 0 }} transition={{ delay: 1 }} key={index} ref={ref}>
            <div className="solution_cards_box">
                <div className="solution_card">
                    <div className="hover_color_bubble"></div>
                    <div className="solu_title">
                        <h3>{item.title}</h3>
                    </div>
                    <div className="solu_description mb-auto">
                        <p>{item.description}</p>
                    </div>
                    <div className="solu_description">
                        <Collapse in={open}>
                            <div>
                                <p className="fs-14 fw-bold mb-0">Explicação Técnica</p>
                                <p className="fs-14">{item.explanation}</p>
                            </div>
                        </Collapse>
                        <div>
                            <button type="button" className="read_more_btn" onClick={() => navigate(item.link)}>Ver projetos</button>
                            <button type="button" className="read_more_btn" onClick={() => setOpen(!open)} >
                                Saiba mais
                                <FontAwesomeIcon className="text-center collapseIcon mt-auto ms-2 me-0" icon={!open ? faArrowAltCircleDown : faArrowAltCircleUp} onClick={() => setOpen(!open)} />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </motion.div>
    )
}


export default About