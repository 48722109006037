import React, { useState, createContext, useContext } from 'react';

const introContext = createContext();

export const useIntro = () => {
    const context = useContext(introContext);
    if (!context) {
        throw new Error('useToggle must be used within a ToggleProvider');
    }
    return context;
};

// Provider component that holds the state and toggle function
export const IntroProvider = ({ children }) => {
    const [skipIntro, setSkipIntro] = useState(false);

    const setSkip = () => {
        setSkipIntro(true);
    };

    return (
        <introContext.Provider value={{ skipIntro, setSkip }}>
            {children}
        </introContext.Provider>
    );
};